<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <!-- <h3>新闻中心</h3>
              <p>NEWS</p> -->
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <!-- <div class="listSlidListInner on">
                <a href="/list">公司要闻</a>
                <span></span>
              </div>
              <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${urlName}business?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd clearfloat">
              <div class="fl listHdName">项目简介</div>
              <div class="fr listHdGrop"><a :href="`${urlName}`">首页</a> · <a :href="`${urlName}business`">项目简介</a> · <span class="txt">{{hdName}}</span> </div>
            </div>
            <div class="listContainer">
              <list-cont-vue ref="listContVue" v-if="pageType == 1" :listId="listId"></list-cont-vue>
              <list-pic-vue ref="listPicVue" v-if="pageType == 2" :listId="listId"></list-pic-vue>
              <list-detail-vue ref="listDetailVue" v-if="pageType == 3" :listId="listId"></list-detail-vue>
              <sub-organication ref="subOrganication" v-if="pageType == 4" :listId="listId"></sub-organication>
              
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import listContVue from '../components/listCont.vue'
import listDetailVue from '../components/listDetail.vue'
import listPicVue from '../components/listPic.vue'
import subOrganication from '../components/subOrganication.vue'
export default {
  name: 'list',
  components: {
    listPicVue,
    listContVue,
    listDetailVue,
    subOrganication
  },
  data(){
    return {
      listId: null,
      pageType: null, // 1:列表；2：图片列表；3：详情；4：组织机构
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '项目简介',
        sub: 'PROJECTS',
      },
      slideList:[
        {
          title: '项目概况',
          id: '1'
        },
        {
          title: '项目掠影',
          id: '2'
        }
      ],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: []
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
    }else{
      _this.active = 0
    }
    _this.getSideNav()
  },
  methods: {
    currentChange(e){
      var _this = this;
      _this.pageNum = e
      _this.getList()
    },
    // 获取二级栏目
    async getSideNav() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: "145"});
      _this.slideList = data.data
      _this.hdTitle = _this.slideList[_this.active].name
      _this.hdName = _this.slideList[_this.active].name
      _this.listId = _this.slideList[_this.active].id

      if (_this.slideList[_this.active].id == 446) {
        _this.pageType = 2
      } else {
        _this.pageType = 1
      }
      // _this.getList()
    },
    // 查列表
    async getList() {
      var _this = this;
      
      _this.loading = true
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: _this.listId, page: _this.pageNum, pageSize: _this.pageSize});
      // var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.listId, page: _this.pageNum, pageSize: _this.pageSize});
      // console.log('data :>> ', data);
      _this.listData = data.data.list
      _this.total = data.data.total
      _this.loading = false
    },
  }
}
</script>